<template>
  <v-card
    outlined
    class="d-flex flex-column"
    :style="cssProps"
  >
    <v-card-title>
      <h5>{{ $t('acquisition-booster.title') }}</h5>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text style="maxHeight: 500px">
      <p>
        {{ $t('acquisition-booster.subtitle') }}
      </p>

      <div v-if="hasApplicableLeads">
        <div
          class="mt-4 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':landingpageSlug}"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="!landingpageSlug"
              :class="{'blink':!landingpageSlug}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':!landingpageSlug}">
              {{ $t('acquisition-booster.bulk-invitation:.body-texts.0') }}
            </h4>
            <v-icon
              v-if="landingpageSlug"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <p>{{ $t('acquisition-booster.bulk-invitation:.body-texts.1') }}</p>
          <InvitationTopicSelect v-model.lazy="landingpageSlug" />
        </div>

        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
          :class="{'finished-step':isStepContactFilterFinished}"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="isStepContactFilter"
              :class="{'blink':isStepContactFilter}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':isStepContactFilter}">
              {{ $t('acquisition-booster.bulk-invitation:.body-texts.2') }}
            </h4>

            <v-icon
              v-if="isStepContactFilterFinished"
              class="ml-2"
              size="20"
              small
              color="primary"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <p>{{ $t('acquisition-booster.bulk-invitation:.body-texts.3') }}</p>
          <v-select
            v-model="contactFilter"
            class="mt-2 mb-2"
            outlined
            validate-on-blur
            :items="contactFilters"
            :disabled="!landingpageSlug"
            :placeholder="$t('labels.select-contact')"
            hide-details
            dense
            required
          />

          <slot name="selectAll" />
        </div>

        <div
          class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
        >
          <div class="d-flex">
            <v-icon
              v-if="isStepSubmit"
              :class="{'blink':isStepSubmit}"
              small
            >
              mdi-arrow-right
            </v-icon>
            <h4 :class="{'blink':isStepSubmit}">
              {{ $t('acquisition-booster.bulk-invitation:.body-texts.4') }}
            </h4>
          </div>

          <p>
            {{ $t('acquisition-booster.bulk-invitation:.body-texts.5') }}
          </p>

          <InvitationButton
            :lead-ids="selectedLeadIds"
            :landingpage-slug="landingpageSlug"
            @invitationSent="invitationSent"
          />

          <InvitationPreview :landingpage-slug="landingpageSlug" />
        </div>
      </div>

      <div v-if="!hasApplicableLeads">
        <p>
          <b>{{ $t('acquisition-booster.bulk-invitation:.no-lead-texts.0') }}</b>
        </p>
        <p v-if="hasCustomFilter">
          {{ $t('acquisition-booster.bulk-invitation:.no-lead-texts.1') }}
        </p>

        <p v-if="hasCustomFilter && hasLeadImport && $vuetify.breakpoint.smAndUp">
          {{ $t('acquisition-booster.bulk-invitation:.no-lead-texts.2') }}
        </p>

        <Feature :feature-slug="featureNames.LEAD_IMPORT">
          <div v-if="$vuetify.breakpoint.smAndUp">
            {{ $t('acquisition-booster.bulk-invitation:.no-lead-texts.3') }}
            <v-btn
              color="primary"
              class="w-full mt-6"
              @click="enableImport"
            >
              <v-icon
                small
                left
              >
                mdi-upload
              </v-icon>
              {{ $t('buttons.acquisition-booster.bulk-invitation.import') }}
            </v-btn>
          </div>
        </Feature>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { InvitationContactFilter, ReadableInvitationContactFilter } from '../enums/Invitation'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import AnimationData from '../../../../public/animations/email-plane.json'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import InvitationPreview from '../Lead/InvitationPreview.vue'
import InvitationButton from '../Lead/InvitationButton.vue'
import InvitationTopicSelect from '../Lead/InvitationTopicSelect.vue'

export default {
  components: {
    InvitationPreview,
    InvitationButton,
    InvitationTopicSelect
  },
  mixins: [brandingMixin, featureMixin],
  props: {
    selectedLeadIds: {
      type: Array,
      default: () => []
    },
    hasCrm: {
      type: Boolean,
      default: false
    },
    crmName: {
      type: String,
      default: ''
    },
    hasApplicableLeads: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contactFilter: null,
      loadingTimeout: null,
      landingpageSlug: null,
      animationData: AnimationData
    }
  },
  computed: {
    contactFilters () {
      const hasValuation = this.isFeatureActive('lp-immobilienbewertung')
      const hasPropertyRequest = this.isFeatureActive('lp-wohntraum-finder')
      const readableInvitationContactFilter = Object.entries(ReadableInvitationContactFilter)
        .map(([key, value]) => ({ value: key, text: this.$t(value) }))
        .filter(({ value }) => value !== InvitationContactFilter.VALUATIONS || hasValuation)
        .filter(({ value }) => value !== InvitationContactFilter.PROPERTY_REQUEST || hasPropertyRequest)

      if (this.hasCrm) {
        const crmFilter = {
          value: InvitationContactFilter.CRM,
          text: this.$t('labels.acquisition-booster.contact-filter.from-crm', { crm: this.crmName })
        }
        readableInvitationContactFilter.splice((readableInvitationContactFilter.length - 1), 0, crmFilter)
      }
      if (this.$vuetify.breakpoint.smAndDown) { return readableInvitationContactFilter.filter(filter => filter.value !== InvitationContactFilter.CUSTOM) }
      return readableInvitationContactFilter
    },
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary,
        '--primary-light': `${this.$vuetify.theme.themes.light.primary}55`
      }
    },
    isStepContactFilterFinished () {
      return this.landingpageSlug && this.contactFilter && this.selectedLeadIds.length > 0
    },
    isStepContactFilter () {
      return this.selectedLeadIds.length <= 0 && this.landingpageSlug
    },
    isStepSubmit () {
      return this.selectedLeadIds.length > 0 && this.landingpageSlug && this.contactFilter
    },
    hasCustomFilter () {
      return this.contactFilter === InvitationContactFilter.CUSTOM
    },
    hasLeadImport () {
      return this.isFeatureActive(this.featureNames.LEAD_IMPORT)
    }
  },

  watch: {
    landingpageSlug () {
      this.$emit('updateFetchIdsParameters', { contactFilter: this.contactFilter, landingpageSlug: this.landingpageSlug })
    },
    contactFilter () {
      if (this.contactFilter !== InvitationContactFilter.CUSTOM) {
        resetAllFilters()
        if (this.contactFilter === InvitationContactFilter.PROPERTY_REQUEST) {
          persistFilter('propertyRequests', true)
        } else if (this.contactFilter === InvitationContactFilter.VALUATIONS) {
          persistFilter('valuations', true)
        } else if (this.contactFilter === InvitationContactFilter.NEW) {
          persistFilter('currentStatus', 'new')
        } else if (this.contactFilter === InvitationContactFilter.CRM) {
          persistFilter('leadOriginCrm', true)
        }
      }
      this.$emit('updateFetchIdsParameters', {
        contactFilter: this.contactFilter,
        landingpageSlug: this.landingpageSlug,
        showFilter: this.contactFilter === InvitationContactFilter.CUSTOM
      })
    }
  },

  methods: {
    invitationSent () {
      this.landingpageSlug = ''
      this.contactFilter = null
      this.$emit('invitationSent')
    },
    close () {
      this.$emit('close')
    },
    enableImport () {
      this.$emit('enableImport')
    }
  }
}
</script>

<style scoped>
.blink{
  animation: blink-animation 4s linear infinite;
  -webkit-animation: blink-animation 4s linear infinite;
}

.-mt-4 {
  margin-top: -4px;
}

@keyframes blink-animation {
  0% {
    color: rgba(0, 0, 0, 0.6);
  }

  50% {
    color: var(--primary);
  }

  100% {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
